.axsis-team-section {
    background-color: #f7f6ff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  }
  
  .team-item {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    background-color: #fafafa;
  }
  .team-item:after {
    content: "";
    position: absolute;
    left: 50%;
    margin-left: 0px;
    bottom: 0;
    width: 0px;
    height: 2px;
    background-color: #6f64e7;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .team-item .team-thumb {
    position: relative;
  }
  .team-item .team-thumb img {
    width: 100%;
  }
  .team-item .team-thumb .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 42px;
    height: 42px;
    margin-top: -21px;
    border-radius: 50%;
    background-color: #252525;
    text-align: center;
    line-height: 42px;
    color: white;
    font-size: 12px;
    transition: all 0.2s ease;
    z-index: 2;
    opacity: 0;
  }
  .team-item .team-thumb .icon.search {
    margin-left: -21px;
  }
  .team-item .team-thumb .icon.link {
    margin-left: -21px;
  }
  .team-item .team-thumb .icon:hover {
    background-color: #6f64e7;
  }
  .team-item .team-thumb:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(111, 100, 231, 0.5);
    opacity: 0;
  }
  .team-item .team-content {
    text-align: center;
    padding: 20px;
  }
  .team-item .team-content .name {
    line-height: 20px;
  }
  .team-item .team-content .desgination {
    font-size: 13px;
    color: #273167;
    margin-bottom: 0;
  }
  .team-item:hover .team-thumb:after {
    opacity: 1;
  }
  .team-item:hover .team-thumb .icon {
    transition: all 0.5s ease;
    opacity: 1;
  }
  .team-item:hover .team-thumb .icon.search {
    margin-left: -35px;
  }
  .team-item:hover .team-thumb .icon.link {
    margin-left: 15px;
  }
  .team-item:hover:hover:after {
    margin-left: -35px;
    width: 70px;
  }
  
  .team-slider-container {
    overflow: hidden;
    position: relative;
    padding-bottom: 90px;
  }
  .team-slider-container .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #ebebeb;
    opacity: 1;
  }
  .team-slider-container .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #6f64e7;
  }