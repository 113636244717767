
.banner-section.style2 {
    position: relative;
    background-image: none;
  }
  @media (min-width: 1500px) {
    .banner-section.style2 {
      padding-top: 120px;
      height: 82vh;
    }
  }
  .banner-section.style2:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0px;
    width: 330px;
    height: 430px;
    background-image: url(../images/banner/right-shape.png);
    background-size: cover;
    display: none;
  }
  @media (min-width: 992px) {
    .banner-section.style2:before {
      display: block;
    }
  }
  @media (min-width: 1500px) {
    .banner-section.style2:before {
      width: 470px;
      height: 600px;
    }
  }
  .banner-section.style2:after {
    content: "";
    position: absolute;
    right: 80px;
    top: 70px;
    width: 325px;
    height: 325px;
    background-image: url(../images/circle-shape.png);
    background-size: cover;
    display: none;
  }
  @media (min-width: 992px) {
    .banner-section.style2:after {
      display: block;
    }
  }
  @media (min-width: 1500px) {
    .banner-section.style2:after {
      width: 525px;
      height: 525px;
    }
  }
  /* .banner-section.style2 .banner-text {
      padding-bottom: 20px;
  } */
  .banner-section.style2 .banner-text h1 {
    font-size: 30px;
    color: #273167;
    margin-bottom: 15px;
    line-height: 1.2;
  }
  @media (min-width: 576px) {
    .banner-section.style2 .banner-text h1 {
      font-size: 36px;
      line-height: 1.2;
    }
  }
  @media (min-width: 1500px) {
    .banner-section.style2 .banner-text h1 {
      font-size: 42px;
      line-height: 1.33;
    }
  }
  .banner-section.style2 .banner-text p {
    color: #273167;
    margin-bottom: 30px;
  }
  .banner-section.style2 .banner-text .play-store .custom-btn {
    padding: 10px 20px;
    border: 1px solid #fff;
    font-weight: 500;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 10px 20px 0 rgba(39, 49, 103, 0.1);
  }
  .banner-section.style2 .banner-text .play-store .custom-btn svg {
    font-size: 27px;
    margin-right: 10px;
    fill: #273167;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .banner-section.style2 .banner-text .play-store .custom-btn p {
    font-size: 12px;
    margin: 0;
    line-height: 1.4;
    color: #273167;
    font-weight: 500;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .banner-section.style2 .banner-text .play-store .custom-btn p span {
    display: block;
    font-size: 17px;
    color: #273167;
    font-weight: 500;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .banner-section.style2 .banner-text .play-store .custom-btn:hover {
    background-color: #273167;
  }
  .banner-section.style2 .banner-text .play-store .custom-btn:hover svg {
    fill: white;
  }
  .banner-section.style2 .banner-text .play-store .custom-btn:hover p {
    color: white;
  }
  .banner-section.style2 .banner-text .play-store .custom-btn:hover p span {
    color: white;
  }
  @media (min-width: 1500px) {
    .banner-section.style2 .banner-image-content {
      padding-top: 200px;
    }
  }