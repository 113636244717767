
.widget {
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 60px; 
}
.widget .widget-wrapper {
    padding: 0 25px; 
}
.widget-title {
    background-color: #f8f8f8;
    padding: 15px 20px;
    color: #273167;
}
.searh-widget {
    border-radius: 40px; 
}