.banner-section.style3 {
    position: relative;
    overflow: visible;
    background-image: url(../images/home3/banner/banner-bg.png);
    background-size: unset;
    background-position: unset;
  }
  @media (min-width: 992px) {
    .banner-section.style3 {
      height: 700px;
    }
  }
  @media (min-width: 1500px) {
    .banner-section.style3 {
      height: 90vh;
    }
  }
  .banner-section.style3:before {
    display: none;
  }
  .banner-section.style3:after {
    display: none;
  }
  .banner-section.style3 .banner-text h1 {
    font-size: 30px;
    color: #273167;
    margin-bottom: 15px;
    line-height: 1.2;
  }
  @media (min-width: 576px) {
    .banner-section.style3 .banner-text h1 {
      font-size: 36px;
      line-height: 1.2;
    }
  }
  @media (min-width: 1500px) {
    .banner-section.style3 .banner-text h1 {
      font-size: 42px;
      line-height: 1.33;
    }
  }
  .banner-section.style3 .banner-text p {
    color: #273167;
    margin-bottom: 30px;
  }
  .banner-section.style3 .banner-text .button-store .custom-btn {
    padding: 10px 20px;
    border: 1px solid #fff;
    font-weight: 500;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 10px 20px 0 rgba(39, 49, 103, 0.1);
  }
  .banner-section.style3 .banner-text .button-store .custom-btn i {
    font-size: 27px;
    margin-right: 10px;
    color: #273167;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .banner-section.style3 .banner-text .button-store .custom-btn p {
    font-size: 12px;
    margin: 0;
    line-height: 1.4;
    color: #273167;
    font-weight: 500;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .banner-section.style3 .banner-text .button-store .custom-btn p span {
    display: block;
    font-size: 17px;
    color: #273167;
    font-weight: 500;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .banner-section.style3 .banner-text .button-store .custom-btn.active {
    background-color: #273167;
  }
  .banner-section.style3 .banner-text .button-store .custom-btn.active i {
    color: white;
  }
  .banner-section.style3 .banner-text .button-store .custom-btn.active p {
    color: white;
  }
  .banner-section.style3 .banner-text .button-store .custom-btn.active p span {
    color: white;
  }
  
  .banner-form {
    background-color: white;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    padding: 20px 30px;
    border-radius: 5px;
    max-width: 465px;
    width: 100%;
    display: inline-block;
  }
  @media (min-width: 992px) {
    .banner-form {
      margin-left: 50px;
    }
  }
  @media (min-width: 1500px) {
    .banner-form {
      padding: 30px 40px;
      margin-left: auto;
    }
  }
  .banner-form h5 {
    color: #01d85f;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0px;
  }
  .banner-form p {
    font-size: 13px;
    margin-bottom: 10px;
  }
  @media (min-width: 1500px) {
    .banner-form p {
      margin-bottom: 30px;
    }
  }
  .banner-form form .form-group {
    margin-bottom: 10px;
  }
  @media (min-width: 1500px) {
    .banner-form form .form-group {
      margin-bottom: 25px;
    }
  }
  .banner-form form label {
    font-size: 13px;
  }
  .banner-form form input {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    border: none;
    padding: 12px 25px;
    height: auto;
  }
  .banner-form form button {
    width: 100%;
    padding: 13px 25px;
    background-color: #01d85f;
    color: white;
    border: none;
    border-radius: 3px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .banner-form form button:hover {
    transform: translateY(-5px);
  }
  
  .banner-section.style3 .banner-content {
    padding-bottom: 65px;
    height: 100%;
  }
  @media (min-width: 992px) {
    .banner-section.style3 .banner-content {
      padding-top: 100px;
      padding-bottom: 165px;
    }
  }
  .banner-section.style3 .banner-content .container, .banner-section.style3 .banner-content .row {
    height: 100%;
  }


  
.button-store3 .custom-btn {
    background-color: #01d75f;
    color: white;
    border-radius: 40px;
    padding: 15px 40px;
    border: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .button-store3 .custom-btn:hover {
    transform: translateY(-5px);
  }
  .button-store3 .play-btn .play {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    margin-right: 10px;
    line-height: 58px;
    text-align: center;
    color: #01d75f;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .button-store3 .play-btn .text {
    text-decoration: underline;
  }
  .button-store3 .play-btn:hover .play {
    background-color: #01d75f;
    color: white;
  }
  