.catagory-list {
    padding-left: 0;
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px; 
}
.catagory-list li a {
    background-color: #fff;
    padding: 10px 0px;
    border-bottom: 1px solid #f2f2f2;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; 
}
.catagory-list li a .count {
    background-color: #f5f5f5;
    padding: 3px 5px;
    color: #273167;
    border-radius: 1px;
    font-size: 11px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; 
}
.catagory-list li a:hover {
    color: #6f64e7; 
}
.catagory-list li a:hover .count {
    background-color: #6f64e7;
    color: #fff; 
}
.catagory-list li:last-child a {
    border-bottom: none; 
}