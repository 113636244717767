.client-logo-container {
    overflow: hidden;
    padding: 40px 50px 30px 50px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
    border-radius: 20px;
  }
.client-logo-container .client-logo {
    text-align: center;
}
.client-logo-container .client-logo img {
    filter: grayscale(100%);
    opacity: 0.3;
}

.client-logo-section.style2 {
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.08);
    position: relative;
    background-color: #f6f9f7;
}
.client-logo-section.style2 .client-logo-container {
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0);
    border-radius: 20px;
}
