.blog-section.style2 {
    overflow: hidden;
}
.blog-section.style2 .col-md-6:last-child:before {
    content: "";
    position: absolute;
    right: -25%;
    bottom: 80px;
    width: 150px;
    height: 150px;
    background-color: #e5e3fb;
    border-radius: 50%;
    z-index: -1;
  }
  @media (min-width: 992px) {
    .blog-section.style2 .col-md-6:last-child:before {
      width: 300px;
      height: 300px;
    }
  }
  .blog-section.style2 .col-md-6:last-child:after {
    content: "";
    position: absolute;
    right: -50%;
    bottom: 25%;
    width: 150px;
    height: 150px;
    background-image: url(../images/circle-shape2.png);
    background-size: cover;
    z-index: -1;
  }
  @media (min-width: 992px) {
    .blog-section.style2 .col-md-6:last-child:after {
      width: 300px;
      height: 300px;
    }
  }



  .home3 .meta-post li .icon {
    color: #01d85f;
  }
  .home3 .active > .post-item .post-content .title a, .home3 .active > .post-item .border-effect .title a {
    color: #01d85f;
  }
  .home3 .active > .post-item .post-content .blog-btn, .home3 .active > .post-item .border-effect .blog-btn {
    color: #01d85f;
  }
  .home3 .col-lg-4:hover > .post-item .post-content .title a, .home3 .col-lg-4:hover > .post-item .border-effect .title a {
    color: #01d85f;
  }
  .home3 .col-lg-4:hover > .post-item .post-content .blog-btn, .home3 .col-lg-4:hover > .post-item .border-effect .blog-btn {
    color: #01d85f;
  }

  .home3 .border-effect:after {
    background-color: #01d85f;
    display: none;
  }