
.get-app-section {
    background-color: #6f64e7;
    position: relative;
    overflow: hidden;
  }
  .get-app-section .play-store .custom-btn {
    padding: 10px 20px;
    border: 1px solid #fff;
    font-weight: 500;
    background-color: transparent;
    border-radius: 3px;
    box-shadow: 0 10px 20px 0 rgba(39, 49, 103, 0.1);
    margin: 5px;
  }
  .get-app-section .play-store .custom-btn svg {
    font-size: 27px;
    margin-right: 10px;
    fill: white;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .get-app-section .play-store .custom-btn p {
    font-size: 12px;
    margin: 0;
    line-height: 1.4;
    color: white;
    font-weight: 500;
    text-align: left;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .get-app-section .play-store .custom-btn p span {
    display: block;
    font-size: 17px;
    color: white;
    font-weight: 500;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .get-app-section .play-store .custom-btn:hover {
    background-color: white;
  }
  .get-app-section .play-store .custom-btn:hover svg {
    fill: #273167;
  }
  .get-app-section .play-store .custom-btn:hover p {
    color: #273167;
  }
  .get-app-section .play-store .custom-btn:hover p span {
    color: #273167;
  }
  .get-app-section .get-image {
    position: absolute;
    width: 60%;
    left: 45%;
    top: 50%;
    transform: translateY(-45%);
  }
  .get-app-section .get-image img {
    width: 100%;
  }


  .get-app-section.style2 {
    background-color: #01d85f;
    background-image: linear-gradient(#03b04f, #01d55e);
  }
  