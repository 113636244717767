

.axsis-testimonial-section.style2 {
    position: relative;
  }
  .axsis-testimonial-section.style2:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    background-color: #6f64e7;
  }
  .axsis-testimonial-section.style2 .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #ebebeb;
    opacity: 1;
  }
  .axsis-testimonial-section.style2 .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #817ffd;
  }


.axsis-testimonial-container {
    padding: 20px 0px 80px 0px;
    position: relative; 
}
.axsis-testimonial-container .testimonial-body {
    padding: 45px 35px 30px 35px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px; 
}
.axsis-testimonial-container .author-image {
    width: 90px;
    height: 90px;
    margin: auto;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden; 
}
.axsis-testimonial-container .author-image img {
    width: 100%; 
}
.axsis-testimonial-container .testimonial-content {
    width: calc(100% - 0px);
    text-align: center;
    padding-left: 0;
    position: relative; 
}
.axsis-testimonial-container .testimonial-content .rating {
    margin-bottom: 10px;
    margin-top: 15px; 
}
.axsis-testimonial-container .testimonial-content .rating i {
    color: #f6c416; 
}
.axsis-testimonial-container .testimonial-content .text {
    margin-bottom: 25px; 
}
.axsis-testimonial-container .testimonial-content .name {
    font-weight: 600;
    margin: 0; 
}
.axsis-testimonial-container .testimonial-content .designation {
    font-size: 14px;
    color: #d1d1d1; 
}
.axsis-testimonial-container .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #ebebeb;
    opacity: 1; 
}
.axsis-testimonial-container .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #6f64e7; 
}

/* Responstive css */
@media (min-width: 576px) {
    .axsis-testimonial-container {
        padding: 20px 20px 80px 20px;
        overflow: hidden; 
    } 
}
@media (min-width: 992px) {
    .axsis-testimonial-container .author-image {
        margin: unset; 
    } 
    .axsis-testimonial-container .testimonial-content {
        width: calc(100% - 90px);
        text-align: left;
        padding-left: 20px; 
    }
    .axsis-testimonial-container .testimonial-content .rating {
        margin-top: 0;
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 0; 
    } 
}





.axsis-testimonial-container2 {
    max-width: 770px;
    width: 100%;
    margin: auto;
    background-color: #fff;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    border-radius: 70px;
    overflow: hidden;
    padding: 50px 50px;
    position: relative;
    padding-bottom: 60px;
  }
  .axsis-testimonial-container2 .testimonial-body {
    text-align: center;
  }
  .axsis-testimonial-container2 .testimonial-body .author-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
  }
  .axsis-testimonial-container2 .testimonial-body .testimonial-content {
    text-align: center;
  }
  .axsis-testimonial-container2 .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #ebebeb;
    opacity: 1;
  }
  .axsis-testimonial-container2 .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #01d85f;
  }
  .axsis-testimonial-container2 .swiper-pagination {
    bottom: 30px;
  }
  
  .axsis-testimonial-container3 {
    padding: 20px 0px 80px 0px;
    position: relative;
  }
  @media (min-width: 576px) {
    .axsis-testimonial-container3 {
      padding: 20px 20px 80px 20px;
      overflow: hidden;
    }
  }
  .axsis-testimonial-container3 .testimonial-body {
    padding: 30px 30px 30px 30px;
    background-color: #fff;
    border: 1px solid #ebebeb;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
  .axsis-testimonial-container3 .testimonial-body:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }
  .axsis-testimonial-container3 .testimonial-body:hover .qoute span {
    color: #2a45c9;
  }
  .axsis-testimonial-container3 .author-image {
    width: 65px;
    height: 65px;
    margin: auto;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
  }
  .axsis-testimonial-container3 .author-image img {
    width: 100%;
  }
  @media (min-width: 992px) {
    .axsis-testimonial-container3 .author-image {
      margin: unset;
    }
  }
  .axsis-testimonial-container3 .author-content {
    width: calc(100% - 65px);
  }
  @media (min-width: 992px) {
    .axsis-testimonial-container3 .author-content {
      padding-left: 20px;
    }
  }
  .axsis-testimonial-container3 .testimonial-content {
    position: relative;
  }
  .axsis-testimonial-container3 .testimonial-content .qoute {
    margin-bottom: 10px;
  }
  .axsis-testimonial-container3 .testimonial-content .qoute span {
    font-size: 30px;
  }
  .axsis-testimonial-container3 .testimonial-content .text {
    margin-bottom: 20px;
    font-size: 14px;
    font-style: italic;
  }
  .axsis-testimonial-container3 .testimonial-content .name {
    font-weight: 600;
    margin: 0;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
  }
  .axsis-testimonial-container3 .testimonial-content .designation {
    font-size: 13px;
    color: #d1d1d1;
  }
  .axsis-testimonial-container3 .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #ebebeb;
    opacity: 1;
  }
  .axsis-testimonial-container3 .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #6f64e7;
  }